import Vue from 'vue'
import Router  from 'vue-router'
const Index = () => import(/* webpackChunkName: "about" */ './views/index.vue');
const Enter = () => import(/* webpackChunkName: "about" */ './views/enter.vue');
const Dynamic = () => import(/* webpackChunkName: "about" */ './views/dynamic.vue');
const Detail = () => import(/* webpackChunkName: "about" */ './views/detail.vue');
const FloorFormat = () => import(/* webpackChunkName: "about" */ './views/floorFormat.vue');
const BranchIntroduction = () => import(/* webpackChunkName: "about" */ './views/branchIntroduction.vue');
const BranchActivities = () => import(/* webpackChunkName: "about" */ './views/branchActivities.vue');
const Dream = () => import(/* webpackChunkName: "about" */ './views/dream.vue');
const Merchant= () => import(/* webpackChunkName: "about" */ './views/merchant.vue');
const Love= () => import(/* webpackChunkName: "about" */ './views/love.vue');
const Join= () => import(/* webpackChunkName: "about" */ './views/join.vue');
const PhoneIndex= () => import(/* webpackChunkName: "about" */ './views/phone/index.vue');
const PhoneEnter= () => import(/* webpackChunkName: "about" */ './views/phone/enter.vue');
const PhoneDynamic= () => import(/* webpackChunkName: "about" */ './views/phone/dynamic.vue');
const PhoneDetail= () => import(/* webpackChunkName: "about" */ './views/phone/detail.vue');
const PhoneFloor= () => import(/* webpackChunkName: "about" */ './views/phone/floor.vue');
const PhoneBranchIntroduction= () => import(/* webpackChunkName: "about" */ './views/phone/branchIntroduction.vue');
const PhoneBranchActivities= () => import(/* webpackChunkName: "about" */ './views/phone/branchActivities.vue');
const PhoneDream= () => import(/* webpackChunkName: "about" */ './views/phone/dream.vue');
const PhoneMerchant= () => import(/* webpackChunkName: "about" */ './views/phone/merchant.vue');
const PhoneJoin= () => import(/* webpackChunkName: "about" */ './views/phone/join.vue');
const PhoneLove= () => import(/* webpackChunkName: "about" */ './views/phone/love.vue');
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);
export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Enter',
            name: 'enter',
            component: Enter,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Dynamic',
            name: 'dynamic',
            component: Dynamic,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Detail',
            name: 'detail',
            component: Detail,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/FloorFormat',
            name: 'floorFormat',
            component: FloorFormat,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/BranchIntroduction',
            name: 'branchIntroduction',
            component: BranchIntroduction,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/BranchActivities',
            name: 'branchActivities',
            component: BranchActivities,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Dream',
            name: 'dream',
            component: Dream,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Merchant',
            name: 'merchant',
            component: Merchant,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Love',
            name: 'love',
            component: Love,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/Join',
            name: 'join',
            component: Join,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneIndex',
            name: 'phoneIndex',
            component: PhoneIndex,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneEnter',
            name: 'phoneEnter',
            component: PhoneEnter,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneDynamic',
            name: 'phoneDynamic',
            component: PhoneDynamic,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneDetail',
            name: 'phoneDetail',
            component: PhoneDetail,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneFloor',
            name: 'phoneFloor',
            component: PhoneFloor,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneBranchIntroduction',
            name: 'phoneBranchIntroduction',
            component: PhoneBranchIntroduction,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneBranchActivities',
            name: 'phoneBranchActivities',
            component: PhoneBranchActivities,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneDream',
            name: 'phoneDream',
            component: PhoneDream,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneMerchant',
            name: 'phoneMerchant',
            component: PhoneMerchant,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneJoin',
            name: 'phoneJoin',
            component: PhoneJoin,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/PhoneLove',
            name: 'phoneLove',
            component: PhoneLove,
            meta: {
                keepAlive: true
            }
        }
    ],
})