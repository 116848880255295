<template>
  <div id="app">
      <div v-if="type==='phone'">
        <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </div>
      <div v-else>
        <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
        <navbar></navbar>
      </div>
  </div>
</template>

<script>
import navbar from "@/components/topBox";
export default {
  name: 'App',
  data(){
    return{
      type:'',
      dialogVisible: false,
      xinxiArr:{},
      list:{},
      img:'',
      isShow:this.show
    }
  },
  components: {
    navbar,
  },
  updated() {
    window.scroll(0, 0);
  },
  created() {
    // this.$nextTick(() => {
    //   // 1.禁用右键菜单
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 2.禁用鼠标选中
    //   document.onselectstart = new Function("event.returnValue=false");
    //   // 3.禁止键盘F12键
    //   document.addEventListener("keydown", function (e) {
    //     if (e.key == "F12") {
    //       e.preventDefault(); // 如果按下键F12,阻止事件
    //     }
    //   });
    // });

  },
  mounted() {
      let s1 = sessionStorage.getItem("user");
    console.log(s1 == null);
    if (s1 != null) {
      return;
    }

    if (this._isMobile()) {
      this.type = "phone";
      this.$router.replace("/PhoneIndex");
      sessionStorage.setItem("user", 1);
    } else {
      this.type = "PC";
      this.$router.replace("/");
      sessionStorage.setItem("user", 1);
    }
    // if (this._isMobile()) {
    //   this.type="phone"
    //   this.$router.replace('/PhoneIndex');
    // } else {
    //   this.type="PC"
    //   this.$router.replace('/');
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
}
</script>

<style>
#app {
  position: relative;
  overflow-y: hidden;
  overflow: hidden;
  min-height: 100%;
  width: 100%;
}
</style>
