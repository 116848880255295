import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "./until/flexible";
import "./assets/css/public.css";
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
// import { Lazyload } from 'vant';
// Vue.use(Lazyload, {
//   lazyComponent: true,
// });
import api from './api'
Vue.prototype.$api = api
// Vue.use(Lazyload);
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
// Vue.directive('title', {//单个修改标题
//   inserted:function (el){
//     document.title=el.dataset.title
//   }
// })
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

