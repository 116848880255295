import axios from 'axios'
const api = axios.create({
    // baseURL: '/api',
    // baseURL: 'http://192.168.2.106/vue-api/web',
    //baseURL: 'http://49.234.66.177/vue-api/web',

    //这里为后端服务地址，不加端口号
    baseURL: 'https://www.xbsmzx.com/web-api/web',
    //baseURL: 'http://49.234.66.177/web-api/web',
    //baseURL: 'http://localhost:8080/web-api/web',
    timeout: 10000,
    responseType: 'json'
})
api.interceptors.response.use(
    response => {
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ status: 1, error: '', data: '' }
         * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 error 会返回错误信息
         * 则代码如下
         */
        if (response.data.code === 200) {
            return Promise.resolve(response.data)
        }else{
            return Promise.reject(response.data)
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default api
