<template>
  <div class="content">
    <div class="navBox">
      <img src="@/assets/img/logo.png" alt="" class="logo" />
      <div class="navLie">
        <div
          class="navItem"
          :class="{ active: path === '/' }"
          @click="nav('/', '0')"
        >
          <img
            src="../assets/img/index.png"
            alt=""
            v-if="path !== '/'"
            class="img"
          />
          <img
            src="../assets/img/index01.png"
            alt=""
            class="img"
            v-else
          />企业首页
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/Enter' }"
          @click="nav('/Enter', 1)"
        >
          <img
            src="../assets/img/icon01.png"
            alt=""
            v-if="path !== '/Enter'"
            class="img"
          />
          <img
            src="../assets/img/icon00.png"
            alt=""
            v-else
            class="img"
          />走进银邦
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/Dynamic' }"
          @click="nav('/Dynamic', 2)"
        >
          <img
            src="../assets/img/icon02.png"
            alt=""
            v-if="path !== '/Dynamic'"
            class="img"
          />
          <img
            src="../assets/img/icon03.png"
            alt=""
            v-else
            class="img"
          />企业动态
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/FloorFormat' }"
          @click="nav('/FloorFormat', 3)"
        >
          <img
            src="../assets/img/icon04.png"
            alt=""
            v-if="path !== '/FloorFormat'"
            class="img"
          />
          <img
            src="../assets/img/icon05.png"
            alt=""
            v-else
            class="img"
          />楼层索引
        </div>
        <div class="navItem" :class="{ active: isShow }" @click="showSw(4)">
          <img
            src="../assets/img/icon06.png"
            alt=""
            v-if="!isShow"
            class="img"
          />
          <img
            src="../assets/img/icon07.png"
            alt=""
            v-else
            class="img"
          />企业党建
          <img src="../assets/img/icon12.png" alt="" class="right" />
        </div>
        <div class="navItem-1" v-if="isShow">
          <div
            class="navItem-1-item"
            :class="{ actives: path === '/BranchIntroduction' }"
            @click="nav('/BranchIntroduction', '')"
          >
            支部简介
          </div>
          <div
            class="navItem-1-item"
            :class="{ actives: path === '/BranchActivities' }"
            @click="nav('/BranchActivities', '')"
          >
            支部活动
          </div>
          <div
            class="navItem-1-item"
            :class="{ actives: path === '/Dream' }"
            @click="nav('/Dream', '')"
          >
            中国梦-商贸梦
          </div>
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/Merchant' }"
          @click="nav('/Merchant', 5)"
        >
          <img
            src="../assets/img/icon08.png"
            alt=""
            class="img"
            v-if="path !== '/Merchant'"
          />
          <img
            src="../assets/img/icon09.png"
            alt=""
            class="img"
            v-else
          />商户之家
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/Join' }"
          @click="nav('/Join', 6)"
        >
          <img
            src="../assets/img/icon10.png"
            alt=""
            class="img"
            v-if="path !== '/Join'"
          />
          <img
            src="../assets/img/icon11.png"
            alt=""
            class="img"
            v-else
          />加入我们
        </div>
        <div
          class="navItem"
          :class="{ active: path === '/Love' }"
          @click="nav('/Love', 7)"
        >
          <img
            src="../assets/img/icon10.png"
            alt=""
            class="img"
            v-if="path !== '/Love'"
          />
          <img
            src="../assets/img/icon11.png"
            alt=""
            class="img"
            v-else
          />爱心公益
        </div>
      </div>
      <div class="erBox">
        <img :src="img" alt="" />
        <div class="num">{{ list.landline }}</div>
        <div class="detail">{{ list.companyName }}</div>
        <div class="flex hao-list">
          <div class="item" :class="{ active1: type == 0 }" @click="qh(0)">
            公众号
          </div>
          <div class="item" :class="{ active1: type == 1 }" @click="qh(1)">
            抖音号
          </div>
          <div class="item" :class="{ active1: type == 2 }" @click="qh(2)">
            视频号
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topBox",
  props: {
    show: Boolean,
    // path:String,
  },
  data() {
    return {
      ee: "",
      dialogVisible: false,
      xinxiArr: {},
      type: 0,
      list: {},
      img: "",
      isShow: this.show,
      path: "/",
    };
  },
  created() {
    this.$forceUpdate();

    let data = sessionStorage.getItem("data");
    if (data) {
      this.list = JSON.parse(data);
      this.img = JSON.parse(data).publicCode;
    } else {
      this.$api.get("/home/company/info").then((res) => {
        let data = res.data;
        this.list = data;
        this.img = data.publicCode;
        sessionStorage.setItem("data", JSON.stringify(data));
      });
    }
  },
  mounted() {
    let s1 = document.querySelector(".navLie");
    console.log(s1.children[0]);

    s1.children[0].querySelector("img").src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHYSURBVHgB7VXLccIwELWM4Rp3EJeQVBBTQZIKYi4wDIeQCgIVJDkAw8lQAekgUEEogXTAnV/eIyvGeGwkmEkOTHZGI2m12rcrPa0c59xEHWPc7/dbSqlbjjebzbBWq73a7rUCiuPYXywWIzgPU0vvpVKpApmbfBiBer1eUCgUCHIlqhaaj9aU+Wy9Xpfr9frsZCCCuK77gWGAxqibOK4h13CMd+hiATWCqQMgIUBG2tFyubxvNBrTpE2n07nyPG+kA4FNOW2jxc0BiSSTXbRZDqjjGm1oC9DPbrfbtAIiswASb9NVaorLvj50JFwjGG05x32+0EfaTmVkokEGxWLxyYZRiSAH6B44ljsbO1kZAUS/kUG1Wq0cA0IBUSIGKIE+7vlOTrDoSz92TpTVajWRoZ8L9Jvi2RjJo43l0e4i5RGjtU2P1RqIVIfDIK3HEZM81JeNPkwGfLjOz4Ok4xCZMTtWDFaGOeuf2BwUm4wC6Wdg4iSh/wKdyUofoJcmJ39Ghn8go4AwF1n6PTKAQSyMIVoLTNrWQTDsBnoOfdbC1H5f9oXaHkC7DzEXCIXwDQyKMAx0cRWQrVOty8iCNS5KqEj79p5NepNUged0FThCpvgA23kf4PnIN3T90D9aMLWfAAAAAElFTkSuQmCC";

    let s2 = sessionStorage.getItem("index");
    console.log("sssss", s2);
    s1.children[0].className = "navItem";

    s1.children[s2].style.color = "#FFB65E";
  },
  methods: {
    showSw(e) {
      sessionStorage.setItem("index", e);
      this.isShow = !this.isShow;
      this.path = "";
    },
    qh(num) {
      if (num == this.type) {
        return;
      }
      this.type = num;
      if (num == 0) {
        this.img = this.list.publicCode;
      } else if (num == 1) {
        this.img = this.list.privateCode;
      } else {
        this.img = this.list.companyLogo;
      }
    },
    nav(num, e) {
      console.log(num, e);
      if (e != "") {
        let s1 = document.querySelector(".navLie");
        console.log(s1);
        let s2 = sessionStorage.getItem("index");

        // ******************
        console.log(num, e);
        sessionStorage.setItem("index", e);

        s1.children[s2].style.color = "#999999";
        s1.children[e].style.color = "#FFB620";
      }

      if (num == this.path) {
        return;
      }
      if (
        num !== "/BranchIntroduction" &&
        num !== "/BranchActivities" &&
        num !== "/Dream"
      ) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
      this.path = num;
      this.$forceUpdate();
      // console.log(num,'222')
      this.$router.replace(num);
    },
  },
};
</script>
<style scoped>
 .hao-list .item{
	 display: flex;
	 align-items: center;
	 justify-content: center;
 }


.navBox {
  width: 20.56rem;
  height: 100vh;
  background: #ffffff;
  border-radius: 0.75rem;
  position: fixed;
  z-index: 999;
  padding-bottom: 20rem;
  top: 0;
  left: 3.312rem;
  /* overflow: scroll; */
}
.logo {
  width: 10rem;
  height: 7.06rem;
  margin: 1.81rem auto 0;
}
.navLie {
  width: 18.06rem;
  padding: 1.1875rem 0 2.75rem;
  border-top: 0.06rem solid #999999;
  border-bottom: 0.06rem solid #999999;
  margin: 0.75rem auto 0;
}
.navItem {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  font-size: 1.125rem;
}
.navItem .img {
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 0.75rem;
  margin-left: 1.06rem;
}
.active {
  background: #f0f2f5;
  border-radius: 0.75rem;
  color: #ffb620;
}
.navItem:hover {
  cursor: pointer;
}
.right {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 8rem;
}
.navItem-1 {
  margin-top: 0.187rem;
  margin-left: 2.125rem;
  position: relative;
}
.navItem-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.875rem;
  width: 0.0625rem;
  height: 6.6875rem;
  background: #999999;
}
.navItem-1:hover {
  cursor: pointer;
}
.navItem-1-item {
  width: 14.8rem;
  height: 2.437rem;
  line-height: 2.437rem;
  padding-left: 0.687rem;
  font-size: 1rem;
  margin-top: 0.187rem;
  border-radius: 0.75rem;
  color: #999999;
}
.actives {
  color: #ffb620;
  background: #f0f2f5;
  position: relative;
}
.actives::before {
  position: absolute;
  content: "";
  width: 0.8rem;
  height: 0.062rem;
  background: #999999;
  top: 0;
  bottom: 0;
  left: -0.8rem;
  margin: auto 0;
}
.erBox {
  margin-top: 0.55rem;
}
.erBox img {
  width: 9.75rem;
  height: 9.75rem;
  border-radius: 0.312rem;
  margin: 0 auto;
}
.num {
  font-size: 1.125rem;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin-top: 1.375rem;
}
.detail {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
}
.flex {
  width: 16.687rem;
  display: flex;
  justify-content: space-around;
  margin: 0.3rem auto 0.3rem;
}
.item {
  width: 4.062rem;
  height: 1.25rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 0.1rem;
  font-size: 0.875rem;
  color: #6b6b6b;
  background: #f0f2f5;
}
.active1 {
  background: #ffb620;
  color: #ffffff;
}
.item:hover {
  cursor: pointer;
}
</style>
